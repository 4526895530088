const PERMISSIONS = {
  Login: '/auth.v1.ManagementAuthService/Login',
  Me: '/auth.v1.ManagementAuthService/Me',
  FetchApp: '/auth.v1.ManagementAuthService/FetchApp',
  CreateApp: '/auth.v1.ManagementAuthService/CreateApp',
  UpdateApp: '/auth.v1.ManagementAuthService/UpdateApp',
  FetchUser: '/auth.v1.ManagementAuthService/FetchUser',
  CreateUser: '/auth.v1.ManagementAuthService/CreateUser',
  UpdateUser: '/auth.v1.ManagementAuthService/UpdateUser',
  FetchRole: '/auth.v1.ManagementAuthService/FetchRole',
  CreateRole: '/auth.v1.ManagementAuthService/CreateRole',
  UpdateRole: '/auth.v1.ManagementAuthService/UpdateRole',
  FetchService: '/auth.v1.ManagementAuthService/FetchService',
  CreateService: '/auth.v1.ManagementAuthService/CreateService',
  UpdateService: '/auth.v1.ManagementAuthService/UpdateService',
  FetchPath: '/auth.v1.ManagementAuthService/FetchPath',
  CreatePath: '/auth.v1.ManagementAuthService/CreatePath',
  UpdatePath: '/auth.v1.ManagementAuthService/UpdatePath',
  FetchPolicy: '/auth.v1.ManagementAuthService/FetchPolicy',
  CreatePolicy: '/auth.v1.ManagementAuthService/CreatePolicy',
  UpdatePolicy: '/auth.v1.ManagementAuthService/UpdatePolicy',
  ReloadEnforcer: '/auth.v1.ManagementAuthService/ReloadEnforcer',
  FetchDNS: '/controlplane.dns.v1.DNSBackOfficeService/FetchDNS',
  CreateDNS: '/controlplane.dns.v1.DNSBackOfficeService/CreateDNS',
  UpdateDNS: '/controlplane.dns.v1.DNSBackOfficeService/UpdateDNS',
  FetchTelco: '/controlplane.telco.v1.BackofficeTelcoService/FetchTelco',
  CreateTelco: '/controlplane.telco.v1.BackofficeTelcoService/CreateTelco',
  UpdateTelco: '/controlplane.telco.v1.BackofficeTelcoService/UpdateTelco',
  FetchLocation: '/controlplane.location.v1.BackofficeLocationService/FetchLocation',
  CreateLocation: '/controlplane.location.v1.BackofficeLocationService/CreateLocation',
  UpdateLocation: '/controlplane.location.v1.BackofficeLocationService/UpdateLocation',
  FetchResAccount: '/controlplane.residential.v1.BackofficeResidentialService/FetchResAccount',
  CreateResAccount: '/controlplane.residential.v1.BackofficeResidentialService/CreateResAccount',
  UpdateResAccount: '/controlplane.residential.v1.BackofficeResidentialService/UpdateResAccount',
  FetchResNode: '/controlplane.residential.v1.BackofficeResidentialService/FetchResNode',
  CreateResNode: '/controlplane.residential.v1.BackofficeResidentialService/CreateResNode',
  UpdateResNode: '/controlplane.residential.v1.BackofficeResidentialService/UpdateResNode',
  FetchResPort: '/controlplane.residential.v1.BackofficeResidentialService/FetchResPort',
  UpdateResPort: '/controlplane.residential.v1.BackofficeResidentialService/UpdateResPort',
  FetchResDevice: '/controlplane.residential.v1.BackofficeResidentialService/FetchResDevice',
  FetchBaseProduct: '/merchant.v1.MerchantService/ListProductBase',
  CreateBaseProduct: '/merchant.v1.MerchantService/CreateProductBase',
  UpdateBaseProduct: '/merchant.v1.MerchantService/UpdateProductBase',
};

const PERMISSIONS_PAGE = {
  '/accounts': [
    PERMISSIONS.FetchUser,
    PERMISSIONS.FetchRole,
    PERMISSIONS.FetchService,
    PERMISSIONS.FetchPath,
    PERMISSIONS.FetchPolicy,
    PERMISSIONS.FetchApp,
  ],
  '/accounts/users': [PERMISSIONS.FetchUser, PERMISSIONS.CreateUser, PERMISSIONS.UpdateUser],
  '/accounts/roles': [PERMISSIONS.FetchRole, PERMISSIONS.CreateRole, PERMISSIONS.UpdateRole],
  '/accounts/services': [PERMISSIONS.FetchService, PERMISSIONS.CreateService, PERMISSIONS.UpdateService],
  '/accounts/paths': [PERMISSIONS.FetchPath, PERMISSIONS.CreatePath, PERMISSIONS.UpdatePath],
  '/accounts/policies': [PERMISSIONS.FetchPolicy, PERMISSIONS.CreatePolicy, PERMISSIONS.UpdatePolicy],
  '/accounts/apps': [PERMISSIONS.FetchApp, PERMISSIONS.CreateApp, PERMISSIONS.UpdateApp],
  '/dns': [PERMISSIONS.FetchDNS, PERMISSIONS.CreateDNS, PERMISSIONS.UpdateDNS],
  '/telco': [PERMISSIONS.FetchTelco, PERMISSIONS.CreateTelco, PERMISSIONS.UpdateTelco],
  '/location': [PERMISSIONS.FetchLocation, PERMISSIONS.CreateLocation, PERMISSIONS.UpdateLocation],
  '/residential/account': [PERMISSIONS.FetchResAccount, PERMISSIONS.CreateResAccount, PERMISSIONS.UpdateResAccount],
  '/residential/node': [PERMISSIONS.FetchResNode, PERMISSIONS.CreateResNode, PERMISSIONS.UpdateResNode],
  '/residential/port': [PERMISSIONS.FetchResPort, PERMISSIONS.UpdateResPort],
  '/residential/device': [PERMISSIONS.FetchResDevice],
  '/residential': [
    PERMISSIONS.FetchResAccount,
    PERMISSIONS.FetchResNode,
    PERMISSIONS.FetchResPort,
    PERMISSIONS.FetchResDevice,
  ],
  '/products': [PERMISSIONS.FetchBaseProduct, PERMISSIONS.CreateBaseProduct, PERMISSIONS.UpdateBaseProduct],
};

export { PERMISSIONS, PERMISSIONS_PAGE };
